import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { RestService } from './shared/services/rest.service';
import { HttpClientModule } from '@angular/common/http';
import { SocketServiceClient } from './shared/services/socket-service-client.service';
import { DeviceService } from './shared/services/device.service';
import { MaterialModule } from '../material/material.module';
import { AuthService } from './shared/services/auth.service';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, MaterialModule],
  providers: [RestService, SocketServiceClient, DeviceService, AuthService],
  bootstrap: [AppComponent],
})
export class AppModule {}
