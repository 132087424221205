import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DeviceService {
  constructor() {}

  private _deviceLiveData = new BehaviorSubject({});
  private _fetchBindings = new BehaviorSubject({});
  deviceLiveData$ = this._deviceLiveData.asObservable();
  fetchBindings$ = this._fetchBindings.asObservable();

  setDeviceLiveData(key: string, value: unknown) {
    if (key === 'fetch_bindings') {
      this._fetchBindings.next(true);
    } else {
      this._deviceLiveData.next({
        ...this._deviceLiveData.value,
        [key]: value,
      });
    }
  }
}
