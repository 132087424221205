import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {
  constructor() {}

  setAccessToken(accessToken: string) {
    localStorage.setItem('guest_access_token', accessToken);
  }

  getAccessToken() {
    return localStorage.getItem('guest_access_token');
  }
  setRefreshToken(refreshToken: string) {
    localStorage.setItem('guest_refresh_token', refreshToken);
  }

  getRefreshToken() {
    return localStorage.getItem('guest_refresh_token');
  }

  setOrganizationId(orgId: string) {
    localStorage.setItem('guest_org_id', orgId);
  }

  getOrganizationId() {
    return localStorage.getItem('guest_org_id');
  }
}
