import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { VideoStreamTokenDTO } from '../interfaces/video.interface';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RestService {
  constructor(private _http: HttpClient, private _authService: AuthService) {}

  getVideoStreamingDetails(payload: VideoStreamTokenDTO) {
    const { deviceId, streamName } = payload;
    const stream_name = `${deviceId}_${streamName}`.replace(new RegExp('-', 'g'), '_');
    const url = environment.videoStreamUrl + '/video_streaming/token/get_streaming_details';
    return this._http.get<any>(url, {
      headers: {
        'Content-Type': 'application/json',
        'device-id': deviceId,
        streamName: stream_name,
        'org-id': this._authService.getOrganizationId(),
        authorization: 'Bearer ' + this._authService.getAccessToken(),
      },
    });
  }

  generateAccessTokenUsingRefreshToken() {
    const url = environment.url + '/guest-sharing-dashboard/access-token';
    return this._http.get(url, {
      headers: {
        authorization: 'Bearer ' + this._authService.getRefreshToken(),
      },
    });
  }

  generateTokensUsingHashedToken(hashedToken: string) {
    const url = environment.url + `/guest-sharing-dashboard/tokens/${hashedToken}`;
    return this._http.get(url);
  }

  getGuestInviteData() {
    const url = environment.url + '/guest-sharing-dashboard/invite';
    return this._http.get(url, {
      headers: {
        authorization: 'Bearer ' + this._authService.getAccessToken(),
      },
    });
  }

  getBrandDetails(): Observable<unknown> {
    const url = `${environment.url}/organization/customer_branding/get_brand_details`;
    return new Observable((observer) => {
      this._http.get(url).subscribe(
        (data) => {
          observer.next(data);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }
}
