export enum ErrorTypes {
  ExpiredLink,
  DisabledLink,
  InvalidLink,
}

export enum PageType {
  JWT_EXPIRED = 'jwt expired',
  INVITATION_EXPIRED = 'Invite link is expired',
  DISABLED_LINK = 'Invite link is disabled',
}

export enum PayloadSocketOperation {
  START_VIDEO_PING = 'video_streaming_ping',
}
