import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { customIcons } from './icons/icon.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    for (const icon of customIcons) {
      this.iconRegistry.addSvgIcon(icon.label, domSanitizer.bypassSecurityTrustResourceUrl(icon.path));
    }
  }
  title = 'guest';

  ngOnInit(): void {
    this._route.queryParams.subscribe(async (params) => {
      if (params['next']) {
        // Redirect to the value of the "next" query parameter
        await this._router.navigate([params['next']]);
        return;
      }
    });
  }
}
