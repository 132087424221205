export const environment = {
  production: false,
  dashboard_logo: window['dashboard_logo'] || 'assets/fb-logo.svg',
  branding_type: window['branding'] || 0,
  partner_logo: window['partner_logo'],
  theme: window['theme'] || '',
  socketServiceClientUrl: 'https://api.on-prem.flytbase.com',
  socketServiceClientPath: '/socket/socket.io',
  url: 'https://api.on-prem.flytbase.com',
  videoStreamUrl: 'https://api.on-prem.flytbase.com',
};
