export interface Icon {
  label: string;
  path: string;
}

export const customIcons: Icon[] = [
  {
    label: 'powered_by_logo',
    path: '/assets/poweredByLogo.svg',
  },
  {
    label: 'powered-by-logo',
    path: '/assets/powered-by-flytbase.svg',
  },
];
