import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DeviceService } from './device.service';
import { AuthService } from './auth.service';
import { PayloadSocketOperation } from '../constants/guest.constant';

const TOPIC_UNSUBSCRIBE_DELAY_MS = 2000;

interface ITopicSubscription {
  numberOfSubscribers: number;
  namespace: string;
}

@Injectable({
  providedIn: 'root',
})
/** client for the SocketService microservice */
export class SocketServiceClient {
  private _socket: Socket | null;
  private droneNamespace = 'flytos';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _topicsListeners: { [topic: string]: (data: unknown) => void } = {};
  private _topicsSubscriptions: { [topic: string]: ITopicSubscription } = {};
  private _topicsUnsubscribeTimeoutIds: { [topic: string]: NodeJS.Timeout } = {};
  public _socketConnectionStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _deviceService: DeviceService, private _authService: AuthService) {}

  connect() {
    if (this._socket) {
      throw new Error('Already connected to SocketService!');
    }
    console.log('connecting to socket server : ', environment.socketServiceClientUrl);
    this._socket = io(environment.socketServiceClientUrl, {
      transports: ['websocket'],
      path: environment.socketServiceClientPath,
      auth: async (cb) => {
        const authToken = this._authService.getAccessToken();
        cb({
          authorization: `Bearer ${authToken}`,
          orgId: this._authService.getOrganizationId(),
        });
      },
      reconnectionAttempts: 5,
      reconnectionDelay: 5000,
    });

    this._socket.on('connect', () => {
      Object.keys(this._topicsSubscriptions).forEach((topic) => {
        this._sendSubscribeEvent(topic);

        const topicListener = (data: unknown) => {
          //   handleTopicEventReceived(topic, data, this._store, this._topicsSubscriptions[topic].namespace);
        };

        this._socket.on(topic, topicListener);
        this._topicsListeners[topic] = topicListener;
      });
      this._socketConnectionStatusSubject.next(true);
    });

    this._socket.on('connect_error', (err) => {
      console.error('Failed to connect to SocketService: ', err);
      this._socketConnectionStatusSubject.next(false);
    });

    this._socket.on('disconnect', (reason) => {
      this._socketConnectionStatusSubject.next(false);
      this._handledDisconnected(reason);
    });
  }

  disconnect() {
    if (!this._socket) {
      throw new Error('Disconnect Error: Not connected to SocketService!');
    }
    this._socket.disconnect();
    this._socket = null;
  }

  private _handledDisconnected(reason: Socket.DisconnectReason) {
    console.error('Socket disconnected with reason: ', reason);
  }

  private _sendSubscribeEvent(topic: string) {
    if (!this._socket || !this._socket.connected) {
      console.log('Socket connection is not established yet. Waiting...');
    } else {
      this._socket.emit('Subscribe', { topic });
    }
  }

  private _sendUnsubscribeEvent(topic: string) {
    this._socket?.emit('Unsubscribe', { topic });
  }

  private _subscribe(topic: string, namespace: string) {
    if (!this._socket) {
      throw new Error('Subscribe Error: Not connected to SocketService!');
    }

    const wasUnsubscribeSheduled = !!this._topicsUnsubscribeTimeoutIds[topic];

    // prevents emitting Unsubscribe event if another subscription occurs within a specific time
    if (wasUnsubscribeSheduled) {
      clearTimeout(this._topicsUnsubscribeTimeoutIds[topic]);
      delete this._topicsUnsubscribeTimeoutIds[topic];
    }

    if (this._topicsSubscriptions[topic]) {
      this._topicsSubscriptions[topic].numberOfSubscribers++;
      return;
    }

    // emit Subscribe event only for the first topic subscription
    this._topicsSubscriptions[topic] = { numberOfSubscribers: 1, namespace: namespace };

    // if unsubscribe was scheduled, means we haven't sent Unubscribe event yet and are already subscribed.
    if (!wasUnsubscribeSheduled) {
      this._sendSubscribeEvent(topic);
    }
    const topicListener = (data: unknown) => {
      const subTopic = topic.split('/')[0];
      if (subTopic === 'fetch_bindings') {
        this._deviceService.setDeviceLiveData(subTopic, data);
      } else {
        this._deviceService.setDeviceLiveData(topic.split('/')[1], data);
      }
    };

    this._socket.on(topic, topicListener);
    this._topicsListeners[topic] = topicListener;
  }

  private _unsubscribe(topic: string) {
    if (!this._socket) {
      // console.warn('Unsubscribe Warning: Not connected to SocketService!');
      return;
    }

    if (!this._topicsSubscriptions[topic]) {
      // console.warn('Unsubscribe Error: Topic not subscribed!');
      return;
    }

    // just decrement, but don't emit Unsubscribe event as other components might still be subscribed
    this._topicsSubscriptions[topic].numberOfSubscribers--;

    // No subscriptions left, safe to unsubscribe now
    if (this._topicsSubscriptions[topic].numberOfSubscribers < 1) {
      // don't unsubscribe immediately, if another subscription happens within given time, Unsubscribe event won't be emitted
      this._topicsUnsubscribeTimeoutIds[topic] = setTimeout(() => {
        this._sendUnsubscribeEvent(topic);
        delete this._topicsUnsubscribeTimeoutIds[topic];
      }, TOPIC_UNSUBSCRIBE_DELAY_MS);

      this._socket.off(topic, this._topicsListeners[topic]);
      delete this._topicsListeners[topic];
      delete this._topicsSubscriptions[topic];
    }
  }

  startSendingVideoPing(timestamp: number, deviceId: string, streamId: string) {
    this._socket.emit('start_sending_video_ping', {
      timestamp: timestamp,
      streamId: streamId,
      deviceId: deviceId,
    });
  }

  startMonitoringStream(data: {
    streamId: string;
    deviceId: string;
    screenType: string;
    platform: string;
    protocol: string;
  }) {
    this._socket.emit('start_monitoring_stream', data);
  }

  stopMonitoringStream(data: { streamId: string; deviceId: string }) {
    this._socket.emit('stop_monitoring_stream', data);
  }

  setStreamQuality(data: { deviceId: string; streamId: string; width: number; height: number }) {
    this._socket.emit('set_stream_quality', data);
  }

  startVideoPing(timestamp: number, deviceId: string, payloadIndex: string) {
    this._socket.emit(PayloadSocketOperation.START_VIDEO_PING, {
      timestamp: timestamp,
      payloadIndex: payloadIndex,
      deviceId: deviceId,
    });
  }
  // DRONE TOPICS SUBSCRIPTIONS
  subscribeToDroneGlobalPosition = (droneId: string) =>
    this._subscribe(`${droneId}/global_position`, this.droneNamespace);
  unsubscribeFromDroneGlobalPosition = (droneId: string) => this._unsubscribe(`${droneId}/global_position`);

  subscribeToFetchBindings = () => this._subscribe(`fetch_bindings`, this.droneNamespace);
  unsubscribeToFetchBindings = () => this._unsubscribe(`fetch_bindings`);
}
